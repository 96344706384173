import Vue from "vue";
import App from "./App.vue";
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAC6yAZ0shbpzr0bMyKlU1RfScli4S-_o",
  authDomain: "virtuelwaitingroom.firebaseapp.com",
  projectId: "virtuelwaitingroom",
  storageBucket: "virtuelwaitingroom.appspot.com",
  messagingSenderId: "418615449414",
  appId: "1:418615449414:web:5339c043afeab6abb4b747",
  measurementId: "G-1F6QTMJ1N1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

Vue.config.productionTip = false;

new Vue({
  app,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png" style="max-width: 25%"/>
    <CheckInPrivate msg="Welcome to Self Check-In" />
  </div>
</template>

<script>
import CheckInPrivate from "./components/CheckInPrivate.vue";

export default {
  name: "App",
  components: {
    CheckInPrivate,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
}
</style>
